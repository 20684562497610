import "typeface-source-sans-pro"
import "typeface-bitter"

// import "prismjs/themes/prism-okaidia.css"
// require("prismjs/plugins/line-numbers/prism-line-numbers.css")

// import "typeface-montserrat"
//  import "typeface-merriweather"

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   )

//   if (answer === true) {
//     window.location.reload()
//   }
// }